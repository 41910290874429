<template>
    <v-dialog :value="true" persistent max-width="800px">
        <v-card light>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h6 text-md-h5" style="word-break: break-word;">
                    Alterar Permissões do Usuário
                </span>
                <v-spacer/>
            </v-card-title>
            <v-card-text
                v-if="loading"
                align="center"
                justify="center"
                style="height: 600px; overflow-x: auto;"
            >
                <div style="display: flex; align-items: center; justify-content: center; width: 100%; margin-top: 60px;">
                    <v-progress-circular
                        indeterminate
                        color="blue lighten-1"
                        :size="50"
                    />
                    <span class="text-h6 ml-4">Carregando...</span>
                </div>
            </v-card-text>
            <v-card-text v-if="!loading" align="center" justify="center">
                <v-row v-if="user">
                    <v-col>
                        {{ user.id }} - {{ user.name }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" sm="6">
                        <v-btn
                            text
                            small
                            color="primary"
                            @click="marcarTodos"
                        >Marcar todos</v-btn>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <v-btn
                            text
                            small
                            color="primary"
                            @click="desmarcarTodos"
                        >Desmarcar todos</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text
                v-if="!loading"
                align="center"
                justify="center"
                style="height: 500px; overflow-x: auto;"
            >
                <v-row v-for="(permission, i) in permissions" :key="i">
                    <v-col cols="12">
                        <v-switch
                            :input-value="permissoesUsuario.includes(permission)"
                            dense
                            inset
                            hide-details
                            class="pb-2"
                            :label="formatLabel(permission)"
                            @change="togglePermissao(permission)"
                            :disabled="!checkPermission('zapercontrol_usuarios_permissoes_atualizar')"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer/>
                <v-btn
                    @click="salvar"
                    :loading="saving"
                    color="success"
                >
                    <v-icon>mdi-content-save</v-icon>
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'PermissionDialog',

    props: {
        value: {
            type: [String, Number],
            default: null,
        },
    },

    data: () => ({
        loading: false,
        saving: false,
        user: null,
        permissions: [],
        permissoesUsuario: [],
    }),

    async mounted() {
        await this.getPermissions();
        await this.getUserById();
    },

    methods: {
        async getPermissions() {
            try {
                const { data } = await this.$http.get('permissions');
                this.permissions = data;
            } catch (e) {
                console.log(e);
            }
        },

        salvar() {
            if (!this.checkPermission('zapercontrol_usuarios_permissoes_atualizar')) {
                alert('Usuário sem permissão');
                return;
            }

            this.saving = true;
            const data =  {
                permissions: this.permissoesUsuario,
            };
            this.$http.post(`users/${this.value}/permissions`, data).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify('Salvo com sucesso');
            }).finally(() => (this.saving = false));

            // this.$emit('close');
        },

        async getUserById() {
            if (!this.value) {
                return;
            }

            if (!this.checkPermission('zapercontrol_usuarios_permissoes_consultar')) {
                alert('Usuário sem permissão');
                return;
            }

            this.loading = true;
            await this.$http.get(`users/${this.value}`).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.user = resp.data;
                this.preencherPermissoes();
            }).finally(() => (this.loading = false));
        },

        marcarTodos() {
            if (!this.checkPermission('zapercontrol_usuarios_permissoes_atualizar')) {
                alert('Usuário sem permissão');
                return;
            }
            this.permissoesUsuario = [...this.permissions];
        },

        desmarcarTodos() {
            if (!this.checkPermission('zapercontrol_usuarios_permissoes_atualizar')) {
                alert('Usuário sem permissão');
                return;
            }
            this.permissoesUsuario = [];
        },

        preencherPermissoes() {
            if (this.user?.permissions) {
                this.permissoesUsuario = this.user.permissions.map(permission => permission.resource);
            }
        },

        togglePermissao(permission) {
            const index = this.permissoesUsuario.indexOf(permission);
            if (index === -1) {
                this.permissoesUsuario.push(permission);
            } else {
                this.permissoesUsuario.splice(index, 1);
            }
        },

        formatLabel(permission) {
            return permission.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.v-input--selection-controls {
    margin-top: 0px;
}
</style>
